// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity-tooltip {
  display: flex;
  flex-direction: column;
  background-color: #323232;
  padding: 1.2rem;
}
.activity-tooltip > span {
  margin-bottom: 0.8rem;
  text-align: center;
  font-weight: bold;
}
.activity-tooltip .fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.activity-tooltip .fields > div {
  display: flex;
  align-items: center;
}
.activity-tooltip .fields > div svg {
  margin-right: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/controls/Overlays/System/ChartTooltip.scss","webpack://./src/stylesheets/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,yBCHY;EDIZ,eAAA;AADF;AAGE;EACE,qBAAA;EACA,kBAAA;EACA,iBAAA;AADJ;AAIE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AAFJ;AAII;EACE,aAAA;EACA,mBAAA;AAFN;AAIM;EACE,oBAAA;AAFR","sourcesContent":["@import \"../../../stylesheets/variables.scss\";\n\n.activity-tooltip {\n  display: flex;\n  flex-direction: column;\n  background-color: $paper-color;\n  padding: 1.2rem;\n\n  & > span {\n    margin-bottom: 0.8rem;\n    text-align: center;\n    font-weight: bold;\n  }\n\n  .fields {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n\n    & > div {\n      display: flex;\n      align-items: center;\n\n      svg {\n        margin-right: 0.8rem;\n      }\n    }\n  }\n}","$browser-prefixes: -webkit-, -moz-, -o-, -ms-;\n\n$paper-color: #323232;\n\n$primary-light: #64b5f6;\n$primary-main: #2196f3;\n$primary-dark: #1976d2;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
